import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { DefaultTemplate } from '@luigiclaudio/ga-baseline-ui/template';
import { useHasMounted, Loader, TransitionItem } from '@luigiclaudio/ga-baseline-ui/helpers';
import { Container, Row, Col, SiteContentWrapper } from '@luigiclaudio/ga-baseline-ui/layout';
import { AuthRecoveryRedirect, useUser } from '@luigiclaudio/ga-auth-theme';
import ConfirmAccountMainContainer from '../components/pageLayout/ConfirmAccountMainContainer';
import locationPropTypes from '../prop-types/locationPropTypes';

const RecoveryPage = ({ location }) => {
    const [user] = useUser();
    const theme = useContext(ThemeContext);
    const hasMounted = useHasMounted();
    const renderBgColor = hasMounted ? theme.site.stage.backgroundColor : null;

    return (
        <DefaultTemplate
            bgStart={renderBgColor}
            bgEnd={renderBgColor}
            content={
                <SiteContentWrapper>
                    <Container isFullHeight>
                        <Row isFullHeight justifyContent="center">
                            <Col xxs={30}>
                                {hasMounted && (
                                    <>
                                        <AuthRecoveryRedirect
                                            recoverPath={location && location.pathname}
                                        />
                                        <ConfirmAccountMainContainer>
                                            <TransitionItem inProp={!user}>
                                                <Loader
                                                    isFullScreen
                                                    fullScreenBackgroundColor={renderBgColor}
                                                />
                                            </TransitionItem>
                                        </ConfirmAccountMainContainer>
                                    </>
                                )}
                            </Col>
                        </Row>
                    </Container>
                </SiteContentWrapper>
            }
        />
    );
};

RecoveryPage.propTypes = {
    location: locationPropTypes.location,
};

RecoveryPage.defaultProps = {
    location: undefined,
};

export default RecoveryPage;
